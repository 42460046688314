/* eslint no-shadow: ["error", { "allow": ["state"] }]*/
export const state = () => ({
	userLoginData: {
		token: {
			value: null,
		},
		role: {
			rank: null,
		},
	},
});

// $store.getters['userLogin/isAllowed']('claimCsc', 'write') || $store.getters['userLogin/isAllowed']('claimPos', 'write')
export const getters = {
	isAllowed: (state) => (resource, operation) => {
		const rank = state.userLoginData.role ? state.userLoginData.role.rank : '';
		const permissions = {
			csc: {
				claimPos: [],
				claimCsc: [ 'read', 'edit', 'write' ],
				diagnostics: [],
				return: [ 'read', 'edit', 'write' ],
				reportCsv: [],
				finished: [ 'read', 'edit', 'write' ],
				technic1: [],
				idr: [],
				statusEdit: [ 'concept', 'concept_csc', 'locked_and_ready', 'closed_now', 'closed_later', 'ready' ],
				superadmin: [],
				// itemsEdit: ['established', 'concept_csc', 'locked_and_ready', 'ready', 'closed_now', 'closed_later']
			},
			pos: {
				claimPos: [ 'read', 'edit', 'write' ],
				claimCsc: [],
				diagnostics: [ 'read', 'edit', 'write' ],
				return: [ 'read', 'edit', 'write' ],
				reportCsv: [],
				finished: [ 'read', 'edit', 'write' ],
				technic1: [],
				idr: [],
				statusEdit: [ 'concept', 'concept_csc', 'ready_for_sign', 'diagnose_now', 'diagnose_concept', 'locked_and_signed', 'diagnose_later', 'locked_and_ready', 'closed_now', 'closed_later', 'ready' ],
				superadmin: [],
			},
			technic1: {
				claimPos: [],
				claimCsc: [],
				diagnostics: [ 'read', 'edit', 'write' ],
				return: [ 'read', 'edit', 'write' ],
				reportCsv: [],
				finished: [],
				technic1: [ 'read', 'edit', 'write' ],
				idr: [],
				statusEdit: [ 'diagnose_concept', 'locked_csc', 'diagnose_later', 'locked_idr' ],
				superadmin: [],
			},
			technic2: {
				claimPos: [],
				claimCsc: [],
				diagnostics: [ 'read', 'edit', 'write' ],
				return: [ 'read', 'edit', 'write' ],
				reportCsv: [],
				finished: [ 'read', 'edit', 'write' ],
				technic1: [],
				idr: [],
				statusEdit: [ 'diagnose_concept', 'locked_csc', 'diagnose_later', 'locked_and_ready', 'closed_now', 'closed_later', 'ready' ],
				superadmin: [],
			},
			business: {
				claimPos: [ 'read', 'edit', 'write' ],
				claimCsc: [ 'read', 'edit', 'write' ],
				diagnostics: [ 'read', 'edit', 'write' ],
				return: [ 'read', 'edit', 'write' ],
				reportCsv: [ 'read', 'edit', 'write' ],
				finished: [ 'read', 'edit', 'write' ],
				technic1: [],
				idr: [],
				statusEdit: [ 'concept', 'concept_csc', 'ready_for_sign', 'diagnose_now', 'diagnose_concept', 'locked_and_signed', 'locked_csc', 'diagnose_later', 'locked_and_ready', 'closed_now', 'closed_later', 'ready' ],
				superadmin: [],
			},
			superadmin: {
				claimPos: [ 'read', 'edit', 'write' ],
				claimCsc: [ 'read', 'edit', 'write' ],
				diagnostics: [ 'read', 'edit', 'write' ],
				return: [ 'read', 'edit', 'write' ],
				reportCsv: [ 'read', 'edit', 'write' ],
				finished: [ 'read', 'edit', 'write' ],
				technic1: [],
				idr: [],
				statusEdit: [ 'concept', 'concept_csc', 'ready_for_sign', 'diagnose_now', 'diagnose_concept', 'locked_and_signed', 'locked_csc', 'diagnose_later', 'locked_and_ready', 'closed_now', 'closed_later', 'ready' ],
				superadmin: [ 'read', 'edit', 'write' ],
			},
			idr: {
				claimPos: [],
				claimCsc: [ 'read', 'edit', 'write' ],
				diagnostics: [],
				return: [],
				reportCsv: [],
				finished: [],
				technic1: [],
				idr: [ 'read', 'edit', 'write' ],
				statusEdit: [ 'concept', 'concept_csc' ],
				superadmin: [],
				// itemsEdit: ['established', 'concept_csc', 'locked_and_ready', 'ready', 'closed_now', 'closed_later']
			},
		};

		// if (rank === 'superadmin') {
		// 	return true;
		// }

		if (rank !== '' && permissions[rank][resource].includes(operation)) {
			return true;
		}
		return false;
	},
};


export const mutations = {
	SET_USER_LOGIN_DATA (state, userLoginData) {
		state.userLoginData = userLoginData;
	},

	// Po odhlášení vymaž user login data a nech jen objekt token s value null
	RESET_USER_LOGIN_DATA (state) {
		let defaultLoginData = {
			token: {
				value: null,
			},
			role: {
				rank: '',
			},
		};
		state.userLoginData = defaultLoginData;
	},
};

export const actions = {
	fetchUserLoginData ({ commit }, response) {
		commit('SET_USER_LOGIN_DATA', response);
	},
};
