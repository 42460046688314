export default function ({ $axios, redirect, error, route, app, store }) {
	$axios.defaults.validateStatus = (status) => {
		return [ 200, 401 ].indexOf(status) !== -1;
	};

	$axios.onResponse(async (response) => {
		if (response.status === 401) {
			if (!response.data.message.includes('LOGIN ERROR:') && response.data.message !== 'User is not allowed to access from current IP address.') {
				redirect(`/?backlink=${ app.localePath(route.path) }&backlinkQuery=${ JSON.stringify(route.query) }`);
			}
			store.commit('userLogin/RESET_USER_LOGIN_DATA');
		}
		return response;
	});

	$axios.onError(err => {
		const code = parseInt(err.response && err.response.status);
		// const isCustomerReport = route.path === '/customer-report' && route.query.token;
		const isCustomerReport = app.localePath('/customer-report') && route.query.token;

		if (isCustomerReport && err.response.data.message === 'Claim was not found.') {
			error({ statusCode: code, message: 'Claim nenalezen.' });
		}

		if (isCustomerReport) {
			return;
		}

		if ((code === 400 || code === 401) && !err.response.data.message.includes('LOGIN ERROR:') && err.response.data.message !== 'User is not allowed to access from current IP address.') {
			// redirect('/');
			redirect(`/?backlink=${ app.localePath(route.path) }&backlinkQuery=${ JSON.stringify(route.query) }`);
		}

		if (code === 400 || code === 401) {
			store.commit('userLogin/RESET_USER_LOGIN_DATA');
		}

		if (code === 503 || code === 500) {
			error({ statusCode: code, message: 'Chyba serveru.' });
		}
	});
}
