//
//
//
//
//
//
//

export default {
	name: 'defaultLayouts',
	middleware: [ 'auth', 'claim' ],
};
