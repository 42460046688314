export default function ({ $axios, store, route, app }) {
	// const isCustomerReport = route.path === '/customer-report' && route.query.token;
	// app.localePath kvuli nuxti18n
	const isCustomerReport = app.localePath('/customer-report') && route.query.token;
	let executeCustomerReportApi = false;

	// Pokud detekuje URL na customer-report, zmeni hodnotu proměnné
	if (isCustomerReport) {
		executeCustomerReportApi = true;
	}

	// Priprava prace s id v parametru URL
	if (route.query.claimId) {
		const claimId = route.query.claimId;
		return $axios.$get('claim/' + claimId)
			.then(response => {
				store.dispatch('reklamace/updateAllReklamaceFields', response.payload);
			});
	}

	// Pokud detekuje stránku customer-report a v query se nachází token, tak fetchne data claimu a uloží je do store
	if (executeCustomerReportApi === true && route.query.token) {
		const token = route.query.token;
		return $axios.$get('claim/customer/' + token)
			.then(response => {
				store.dispatch('reklamace/updateAllReklamaceFields', response.payload);
			});
	}
}
