import Vue from 'vue';
import flatpickr from 'flatpickr';
import { Czech } from 'flatpickr/dist/l10n/cs.js';
import { Slovak } from 'flatpickr/dist/l10n/sk.js';
import VueFlatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import 'flatpickr/dist/themes/material_green.css';

export default ({ app }) => {
	if (app.i18n.locale === 'cs') {
		flatpickr.localize(Czech);
	}

	if (app.i18n.locale === 'sk') {
		flatpickr.localize(Slovak);
	}
};

Vue.use(VueFlatPickr);
